import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const classes = {

};

function SignUpPage() {

    // Reset login status
    useEffect(() => { 
        document.title = "DFQ Admin - Sign Up";
        //dispatch(userActions.logout()); 
    }, []);


    return (
        <>
            <CssBaseline />
            <Container sx={{ textAlign: 'center' }}>
                <Typography variant='h1'>Sign Up Page</Typography>
                <Typography variant='h5'><Link to='/'>Go back</Link></Typography>
            </Container>
        </>
    );
}

export { SignUpPage };