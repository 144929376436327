import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import StockTruck from '../../_resources/stock-truck.png';

const classes = {
	firstContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    loginButton: {
		backgroundColor: '#308cdc',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	signUpButton: {
		backgroundColor: '#20ac9c',
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	buttonBox: {
		float: 'right',
		margin: 3
	},
};

function LandingPage() {

    // Reset login status
    useEffect(() => { 
        document.title = "DFQ Admin";
        //dispatch(userActions.logout());
    }, []);

    return (
        <>
            <CssBaseline />
            <Container maxWidth={false} disableGutters sx={classes.firstContainer}>
                <Box sx={classes.buttonBox}>
                    <Link to='/signup'><Button variant="contained" sx={classes.signUpButton}>Sign Up</Button></Link>
                    <Link to='/login'><Button variant="contained" sx={classes.loginButton}>Log In</Button></Link>
                </Box>
                <Grid container sx={{ minHeight: '60vh', paddingTop: '5%' }}>
                    <Grid size={{ xs: 12, sm: 6}} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant='h1' sx={{color: '#1EBBD7'}}>DFQ,</Typography>
                            <Typography variant='h1' sx={{color: '#1EBBD7'}}>Admin.</Typography>
                            <Typography variant='h5'>A simple and efficient quoting system </Typography>
                            <Typography variant='h5'>so you can focus on what you do best</Typography>
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6}} >
                        <img src={StockTruck} height={400} alt='stock-truck'/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export { LandingPage };