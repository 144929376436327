import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from "react-router-dom";
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { SignUpPage } from './pages/SignUpPage'

function App() {
	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route exact path="/" element={<LandingPage />} title="Landing"/>
				<Route path="/login" element={<LoginPage />} title="Log In"/>
				<Route path="/signup" element={<SignUpPage />} title="Sign Up"/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;